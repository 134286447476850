// import Balloon from "./scripts/balloon";
// import Canvas from "./scripts/canvas";
import Game from "./scripts/game";


document.addEventListener('DOMContentLoaded', () =>  {
  
  let game1 = new Game();
  game1.start();
  // let score = document.getElementById('score');
  // score.textContent = `Score: ${game1.score}`;

});